import { Dispatch, SetStateAction, useEffect } from "react";
import { CheckCircleIcon } from "@heroicons/react/24/solid";
import { Modal as ReactModal } from "react-responsive-modal";

export default function Alert({
    content,
    show,
    setShow,
}: {
    content: string;
    show: boolean;
    setShow: Dispatch<SetStateAction<boolean>>;
}) {
    useEffect(() => {
        if (show) {
            const timeout = setTimeout(() => setShow(false), 3000);
            return () => clearTimeout(timeout);
        }
    }, [show]);

    const onCloseModal = () => setShow(false);

    return (
        <ReactModal
            open={show}
            onClose={onCloseModal}
            focusTrapped={false}
            showCloseIcon={false}
            classNames={{
                modal: "modal-radius-5 modal-padding-0 modal-margin-0",
                root: "alert-modal-root size-fit -translate-x-1/2 -translate-y-1/2",
                overlay: "hidden",
            }}
        >
            <div
                onClick={onCloseModal}
                className="translate-1/2 min-w-75 flex w-fit max-w-5xl cursor-pointer items-center justify-between bg-secondary px-3 py-2.5 hover:brightness-75"
            >
                <div className="flex size-5 items-center gap-2 p-0">
                    <div className="flex items-center justify-center">
                        <CheckCircleIcon className="size-4 text-white" />
                    </div>

                    <p className="whitespace-nowrap text-sm font-normal leading-4 text-white">{content}</p>
                </div>
            </div>
        </ReactModal>
    );
}
