import MemoFullBlockbeatLogo from "@assets/SVG/Logo/FullBlockbeatLogo";
import { QuestionMarkCircleIcon } from "@heroicons/react/24/outline";
import Tooltip from "@/components/Tooltip";

const ComingSoon = ({ text }: { text: string }) => {
    return (
        <div className="relative flex h-full items-center justify-center bg-base-100">
            <span className="flex flex-col items-center gap-6 text-sm text-primary">
                <MemoFullBlockbeatLogo className="scale-[12] opacity-50" />
                <Tooltip
                    className="tooltip-custom-warning flex cursor-pointer items-center justify-center gap-1 text-xs font-light hover:brightness-95"
                    content="Coming Soon"
                >
                    <p className="whitespace-nowrap text-neutral opacity-50">{text}</p>
                    <QuestionMarkCircleIcon className="size-4 text-neutral opacity-50" />
                </Tooltip>
            </span>
        </div>
    );
};

export default ComingSoon;
