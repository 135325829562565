import { News, Shortlink } from "@/types/api";
import { FilterSet } from "@/types/util";
import { useMutation, useQuery } from "@tanstack/react-query";
import { queryClient } from "./queryclient";
import { ulid } from "ulid";
import useJwt from "./session";
import { vercelEnv } from "./environment";

const isProduction = vercelEnv === "production";

const host = isProduction ? "prod" : "dev";
const NEWS_API_URL =
    process.env.NEXT_PUBLIC_BASE_URL_HEROKU ||
    (isProduction ? `https://api-prod.blockbeat.io` : `https://api-dev.blockbeat.io`);

async function fetchNews(time = String(new Date().getTime())): Promise<News[]> {
    const res = await fetch(`${NEWS_API_URL}/list?` + new URLSearchParams({ time }));
    if (!res.ok) {
        throw new Error("Failed to fetch data");
    }
    return res.json();
}

export const useFetchNews = (time = String(new Date().getTime())) => {
    const queryKey = ["news", time];
    return useQuery({
        queryKey,
        queryFn: () => fetchNews(time),
    });
};

async function fetchReadList(jwt): Promise<string[]> {
    const res = await fetch(`${NEWS_API_URL}/my-read-list`, {
        headers: { jwt },
    });
    if (!res.ok) {
        throw new Error("Failed to fetch data");
    }
    return res.json();
}

export const useReadList = () => {
    const jwt = useJwt();

    const queryKey = ["readList"];
    return useQuery({
        queryKey,
        queryFn: () => fetchReadList(jwt),
        enabled: !!jwt,
    });
};

async function fetchVoteList(jwt): Promise<any> {
    const res = await fetch(`${NEWS_API_URL}/my-vote-list`, {
        headers: { jwt },
    });
    if (!res.ok) {
        throw new Error("Failed to fetch data");
    }

    return res.json();
}

export const useVoteList = () => {
    const jwt = useJwt();

    const queryKey = ["voteList"];
    return useQuery({
        queryKey,
        queryFn: () => fetchVoteList(jwt),
        enabled: !!jwt,
    });
};

async function fetchNewsItem(slug: string): Promise<any> {
    const res = await fetch(`${NEWS_API_URL}/slug/${slug}`);
    if (!res.ok) {
        throw new Error("Failed to fetch data");
    }
    return res.json();
}

export const useFetchNewsItem = (slug: string) => {
    const decodedSlug = decodeURIComponent(slug);
    const queryKey = ["news", decodedSlug];

    return useQuery<News>({
        queryKey,
        queryFn: () => fetchNewsItem(slug),
        enabled: !!slug,
    });
};

export const prefetchNewsItem = async (slug: string) => {
    const queryKey = ["news", slug];

    // Check if the data is already in the cache
    const cachedData = queryClient.getQueryData(queryKey);

    if (!cachedData) {
        await Promise.resolve(
            queryClient.prefetchQuery({
                queryKey,
                queryFn: () => fetchNewsItem(slug),
            }),
        );
    }
};

async function markAsRead(slug: string, jwt) {
    const res = await fetch(`${NEWS_API_URL}/mark-as-read/${slug}`, {
        method: "PATCH",
        headers: { jwt },
    });
    if (!res.ok) {
        throw new Error("Failed");
    }
    return res.json();
}

export const useMarkAsRead = () => {
    const jwt = useJwt();

    return useMutation({
        mutationFn: (slug: string) => markAsRead(slug, jwt),

        onMutate: async (variables) => {
            await queryClient.cancelQueries({ queryKey: ["readList"] });
            const previousSet: string[] = queryClient.getQueryData(["readList"]) || [];

            queryClient.setQueryData(["readList"], (old: FilterSet[]) => {
                return [...old, variables];
            });
            return { previousSet };
        },

        onSuccess: () => {
            // Invalidate and refetch any queries that might be affected by this mutation
            queryClient.invalidateQueries({ queryKey: ["readList"] });
        },

        onError: (error, variables, context) => {
            // Rollback to the previous state in case of an error
            queryClient.setQueryData(["readList"], context?.previousSet);
            queryClient.invalidateQueries({ queryKey: ["readList"] });
        },
    });
};

async function markAsUnread(slug: string, jwt) {
    const res = await fetch(`${NEWS_API_URL}/mark-as-unread/${slug}`, {
        method: "PATCH",
        headers: { jwt },
    });
    if (!res.ok) {
        throw new Error("Failed");
    }
    return res.json();
}

export const useMarkAsUnread = () => {
    const jwt = useJwt();

    return useMutation({
        mutationFn: (slug: string) => markAsUnread(slug, jwt),

        onMutate: async (variables) => {
            await queryClient.cancelQueries({ queryKey: ["readList"] });
            const previousSet: string[] = queryClient.getQueryData(["readList"]) || [];
            const index = previousSet.findIndex((s) => s === variables);
            if (index > -1) {
                queryClient.setQueryData(["readList"], (old: string[]) => {
                    const newArray = [...old];
                    newArray.splice(index, 1);
                    return newArray;
                });
            }
            return { previousSet };
        },

        onSuccess: () => {
            // Invalidate and refetch any queries that might be affected by this mutation
            queryClient.invalidateQueries({ queryKey: ["readList"] });
        },

        onError: (error, variables, context) => {
            // Rollback to the previous state in case of an error
            queryClient.setQueryData(["readList"], context?.previousSet);
            queryClient.invalidateQueries({ queryKey: ["readList"] });
        },
    });
};

async function cloneFilterSet({ filterSet, jwt }: { filterSet: FilterSet; jwt }) {
    const res = await fetch(`${NEWS_API_URL}/filters`, {
        body: JSON.stringify(filterSet),
        method: "POST",
        headers: { jwt, "content-type": "application/json" },
    });
    if (!res.ok) {
        throw new Error("Failed");
    }
    return res.json();
}

export const useCloneFilterSet = () => {
    const jwt = useJwt();

    return useMutation({
        mutationFn: ({ filterSet }: { filterSet: FilterSet }) => cloneFilterSet({ filterSet, jwt }),

        onMutate: async (variables) => {
            await queryClient.cancelQueries({ queryKey: ["filterSets"] });
            const previousSet = queryClient.getQueryData(["filterSets"]);

            queryClient.setQueryData(["filterSets"], (old: FilterSet[]) => {
                return [...old, { ...variables.filterSet, filterIndex: ulid() }];
            });

            return { previousSet };
        },

        onSuccess: () => {
            // Invalidate and refetch any queries that might be affected by this mutation
            queryClient.invalidateQueries({ queryKey: ["filterSets"] });
        },

        onError: (error, variables, context) => {
            // Rollback to the previous state in case of an error
            queryClient.setQueryData(["filterSets"], context?.previousSet);
            queryClient.invalidateQueries({ queryKey: ["filterSets"] });
        },
    });
};

async function saveFilterSetById({ filterSet, jwt }: { filterSet: FilterSet; jwt }) {
    const res = await fetch(`${NEWS_API_URL}/filters/${filterSet.filterIndex}`, {
        body: JSON.stringify(filterSet),
        method: "POST",
        headers: { jwt, "content-type": "application/json" },
    });
    if (!res.ok) {
        throw new Error("Failed");
    }
    return res.json();
}

export const useSaveFilterSetById = () => {
    const jwt = useJwt();

    return useMutation({
        mutationFn: ({ filterSet }: { filterSet: FilterSet }) => saveFilterSetById({ filterSet, jwt }),

        onMutate: async (variables) => {
            await queryClient.cancelQueries({ queryKey: ["filterSets"] });
            const previousSet = queryClient.getQueryData(["filterSets"]);

            queryClient.setQueryData(["filterSets"], (old: FilterSet[]) => {
                const index = old.findIndex((f) => f.filterIndex === variables.filterSet.filterIndex);
                if (index > -1) {
                    const newArray = [...old];
                    newArray.splice(index, 1, variables.filterSet);
                    return newArray;
                }
            });
            return { previousSet };
        },

        onSuccess: () => {
            // Invalidate and refetch any queries that might be affected by this mutation
            queryClient.invalidateQueries({ queryKey: ["filterSets"] });
        },
        onError: (error, variables, context) => {
            // Rollback to the previous state in case of an error
            queryClient.setQueryData(["filterSets"], context?.previousSet);
            queryClient.invalidateQueries({ queryKey: ["filterSets"] });
        },
    });
};

async function deleteFilterSetById({ filterSet, jwt }: { filterSet: FilterSet; jwt }) {
    const res = await fetch(`${NEWS_API_URL}/filters/delete/${filterSet.filterIndex}`, {
        body: JSON.stringify(filterSet),
        method: "POST",
        headers: { jwt, "content-type": "application/json" },
    });

    if (!res.ok) {
        throw new Error("Failed");
    }
    return res.json();
}

export const useDeleteFilterSet = () => {
    const jwt = useJwt();

    return useMutation({
        mutationFn: ({ filterSet }: { filterSet: FilterSet }) => deleteFilterSetById({ filterSet, jwt }),

        onMutate: async (variables) => {
            await queryClient.cancelQueries({ queryKey: ["filterSets"] });
            const previousSet = queryClient.getQueryData(["filterSets"]);

            queryClient.setQueryData(["filterSets"], (old: FilterSet[]) => {
                return old.filter((f) => f.filterIndex !== variables.filterSet.filterIndex);
            });

            return { previousSet };
        },

        onSuccess: () => {
            // Invalidate and refetch any queries that might be affected by this mutation
            queryClient.invalidateQueries({ queryKey: ["filterSets"] });
        },

        onError: (error, variables, context) => {
            // Rollback to the previous state in case of an error
            queryClient.setQueryData(["filterSets"], context?.previousSet);
            queryClient.invalidateQueries({ queryKey: ["filterSets"] });
        },
    });
};

async function fetchFilterSets(jwt) {
    const res = await fetch(`${NEWS_API_URL}/filters`, {
        headers: { jwt },
    });
    if (!res.ok) {
        throw new Error("Failed");
    }

    const data = await res.json();

    const newF = data.map((fi: FilterSet) => {
        if (Object.keys(fi).includes("showPrimarySourcesOnly")) return fi;
        return {
            ...fi,
            showPrimarySourcesOnly: false,
        };
    });

    return newF;
}

export const useFilterSets = () => {
    const jwt = useJwt();

    const queryKey = ["filterSets"];
    return useQuery<FilterSet[]>({
        queryKey,
        queryFn: () => fetchFilterSets(jwt),
        enabled: !!jwt,
    });
};

async function newsVote({ slug, vote, jwt }) {
    return await fetch(`${NEWS_API_URL}/slug/${slug}/vote`, {
        method: "POST",
        headers: { jwt, "content-type": "application/json" },
        body: JSON.stringify({ vote }),
    });
}

export const useNewsVote = () => {
    const jwt = useJwt();

    return useMutation({
        mutationFn: ({ slug, vote }: { slug: string; vote: number }) => newsVote({ slug, vote, jwt }),

        onMutate: async (variables) => {
            await queryClient.cancelQueries({ queryKey: ["voteList"] });
            queryClient.setQueryData(["voteList"], (old: any) => {
                return { ...old, [variables.slug]: variables.vote };
            });
            return variables.slug;
        },

        onSuccess: (data, variables, context) => queryClient.refetchQueries({ queryKey: ["voteList"] }),

        onError: (error, variables, context) => {
            queryClient.setQueryData(["voteList"], (old: any) => {
                // Rollback to the previous state in case of an error
                const { [variables.slug]: _, ...rest } = old;
                return rest;
            });
        },
    });
};

async function fetchTwitterHandles() {
    const res = await fetch(`https://twitter.${host}.blockbeat.blockbeat.link/`);
    if (!res.ok) {
        throw new Error("Failed");
    }
    return res.json();
}

async function addTwitterHandle(username: string) {
    const res = await fetch(`https://twitter.${host}.blockbeat.blockbeat.link/${username}`);
    if (!res.ok) {
        throw new Error("Failed");
    }
    return res.json();
}

async function fetchAverageSentiment(): Promise<any> {
    // const res = await fetch(`${NEWS_API_URL}/average-sentiment`);
    // if (!res.ok) {
    //     throw new Error("Failed to fetch data");
    // }
    // return res.json();
}

async function getShortlinkBySlug(slug: string): Promise<Shortlink> {
    const res = await fetch(`${NEWS_API_URL}/shortlink/slug/${slug}`);
    const data = await res.json();
    return data;
}

export function useShortlinkBySlug(slug: string, show: boolean) {
    return useQuery<Shortlink>({
        queryKey: ["shortlinkBySlug", slug],
        queryFn: () => getShortlinkBySlug(slug),
        enabled: !!slug && show,
    });
}

export async function getNewsByShortlink({ shortlink }): Promise<News> {
    const res = await fetch(`${NEWS_API_URL}/shortlink/${shortlink}`);
    return res.json();
}

export function useNewsByShortlink(shortlink: string) {
    return useQuery<News>({
        queryKey: ["newsByShortlink", shortlink],
        queryFn: () => getNewsByShortlink({ shortlink }),
        enabled: !!shortlink, // Only fetch if shortlink exists
    });
}

export async function createRewardForShortlinkClick({ referredUserId, eventData, shortlink }): Promise<any> {
    const res = await fetch(`${NEWS_API_URL}/shortlink/click`, {
        method: "POST",
        body: JSON.stringify({ referredUserId, eventData, shortlink }),
    });
    if (!res.ok) {
        throw new Error("Failed to post data");
    }
    return res.json();
}

export async function untagNews({ slug, tag }): Promise<any> {
    const res = await fetch(`${NEWS_API_URL}/slug/${slug}/untag`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({ tag }),
    });
    if (!res.ok) {
        throw new Error("Failed to post data");
    }
    return res.json();
}

export async function tagNews({ slug, tag }): Promise<any> {
    const res = await fetch(`${NEWS_API_URL}/slug/${slug}/tag`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({ tag }),
    });
    if (!res.ok) {
        throw new Error("Failed to post data");
    }
    return res.json();
}

// export async function scrapeNews(url): Promise<any> {
//     const res = await fetch(`https://q43cu7hse6.execute-api.us-east-1.amazonaws.com/prod/scrape`, {
//         method: "POST",
//         headers: {
//             "Content-Type": "application/json",
//         },
//         body: JSON.stringify({ url }),
//     });
//     if (!res.ok) {
//         throw new Error("Failed to post data");
//     }
//     return res.json();
// }

export async function createNewPost(news: any): Promise<any> {
    const res = await fetch(`https://d5fe2ie23a.execute-api.us-east-1.amazonaws.com/prod/new-post`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(news),
    });
    if (!res.ok) {
        throw new Error("Failed to post data");
    }
    return res.json();
}

// POST request to news API to create a new keyword
export async function createKeyword(list = "other", value: string, score: number): Promise<any> {
    const res = await fetch(`${NEWS_API_URL}/keywords/add`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({ key: list, item: { score, value } }),
    });
    if (!res.ok) {
        throw new Error("Failed to post data");
    }
    return res.json();
}
