import { untagNews } from "@/services/news";
import { BuildingOfficeIcon, TagIcon, UserIcon } from "@heroicons/react/24/outline";
import { useStytchUser } from "@stytch/nextjs";
import { MouseEventHandler, useContext, useState } from "react";
import NewsItemContext from "./NewsItemContext";
import { categories, companiesInvolvedInCrypto, people } from "@/types/util";
import { get } from "lodash";

export default function NewItemTag({
    tag,
    isSelected,
    isRead,
    onClick,
}: {
    tag: {
        type: string;
        data: string;
    };
    isSelected: boolean;
    isRead: boolean;
    onClick?: MouseEventHandler<HTMLDivElement>;
}) {
    const convertToTitleCase = (str) => {
        if (str === str?.toLowerCase()) {
            return str?.replace(/\b\w/g, function (txt) {
                return txt?.charAt(0)?.toUpperCase() + txt?.substr(1)?.toLowerCase();
            });
        }
        return str;
    };
    const news = useContext(NewsItemContext);

    const [removed, setRemoved] = useState(false);

    const removeTag = async (e) => {
        e.stopPropagation();
        await untagNews({ slug: news.slug, tag: tag });
        setRemoved(true);
    };

    // manual supervision - tag removal logic
    const data = useStytchUser();
    const firstName = get(data, "user.name.first_name", "") as string;
    const lastName = get(data, "user.name.last_name", "") as string;
    const isIsaac = firstName === "Isaac" && lastName === "Horowitz";
    const isRoss = firstName === "Ross" && lastName === "Ragsdale";
    const isAdmin = isIsaac || isRoss;

    if (removed) return null;

    let desiredTag = tag;

    const getTagType = (tag) => {
        const isOrg = companiesInvolvedInCrypto.some((company) => company === tag);
        const isProduct = categories.some((company) => company === tag);
        const isPerson = people.some((company) => company === tag);

        if (isOrg) {
            return (desiredTag = {
                data: tag,
                type: "ORG",
            });
        }

        if (isProduct) {
            return (desiredTag = {
                data: tag,
                type: "PRODUCT",
            });
        }

        if (isPerson) {
            return (desiredTag = {
                data: tag,
                type: "PERSON",
            });
        }

        return;
    };

    if (!tag.type) {
        getTagType(tag);
    }

    return (
        <div
            className="flex cursor-pointer items-center gap-[3px]"
            onClick={onClick}
            // onClick={(e) => isAdmin && removeTag(e)}
        >
            {desiredTag.type === "ORG" ? (
                <BuildingOfficeIcon
                    className="size-3"
                    color={isSelected ? "#A9C0F0" : isRead ? "#505A68" : "#505A68"}
                />
            ) : desiredTag.type === "PERSON" ? (
                <UserIcon
                    className="size-3"
                    color={isSelected ? "#A9C0F0" : isRead ? "#505A68" : "#505A68"}
                />
            ) : (
                desiredTag.type === "PRODUCT" && (
                    <TagIcon
                        className="size-3"
                        color={isSelected ? "#A9C0F0" : isRead ? "#505A68" : "#505A68"}
                    />
                )
            )}
            {/* This is a reference incase we have other categories later. The icons to be used are the following. */}
            {/* 
            //     <MemoBrain
            //     <MemoClock
            */}

            <p
                className={`text-[10px] font-light leading-3 ${
                    isSelected ? "text-alice-blue" : isRead ? "text-light-gray" : "text-neutral"
                } ${isAdmin && "hover:text-red-500"}`}
            >
                {convertToTitleCase(desiredTag.data)}
            </p>
        </div>
    );
}
