import { useMutation, useQuery } from "@tanstack/react-query";
import { queryClient } from "./queryclient";
import useJwt from "./session";
import { vercelEnv } from "./environment";

const isProduction = vercelEnv === "production";

const API_URL =
    process.env.NEXT_PUBLIC_BASE_URL_HEROKU ||
    (isProduction ? `https://api-prod.blockbeat.io` : `https://api-dev.blockbeat.io`);

async function fetchBookmarks(jwt): Promise<any> {
    const res = await fetch(`${API_URL}/my-bookmark-list`, {
        headers: { jwt },
    });
    if (!res.ok) {
        throw new Error("Failed to fetch data");
    }

    const data = await res.json();
    return data.bookmarks;
}

export const useBookmarks = () => {
    const jwt = useJwt();

    const queryKey = ["bookmarks"];
    return useQuery<string[]>({
        queryKey,
        queryFn: () => fetchBookmarks(jwt),
        enabled: !!jwt,
    });
};

const createBookmark = async (slug: string, url: string, jwt) => {
    const res = await fetch(`${API_URL}/bookmarks/create-bookmark`, {
        method: "POST",
        headers: {
            jwt,
            "Content-Type": "application/json",
        },
        body: JSON.stringify({ slug, url }),
    });

    if (!res.ok) {
        throw new Error("Failed to make request");
    }

    return await res.json();
};

export const useCreateBookmarkMutation = () => {
    const jwt = useJwt();

    return useMutation({
        mutationFn: ({ slug, url }: { slug: string; url: string }) => createBookmark(slug, url, jwt),

        onMutate: async (variables) => {
            await queryClient.cancelQueries({ queryKey: ["bookmarks"] });

            queryClient.setQueryData(["bookmarks"], (old: any) => {
                return [...old, variables.slug];
            });

            return variables.slug;
        },

        onSuccess: (data, variables, context) => queryClient.refetchQueries({ queryKey: ["bookmarks"] }),

        onError: (error, variables, context) => {
            queryClient.setQueryData(["bookmarks"], (old: any) =>
                old.filter((bookmark: any) => bookmark.slug !== context),
            );
        },
    });
};

const deleteBookmark = async (slug: string, jwt) => {
    const res = await fetch(`${API_URL}/bookmarks/delete-bookmark`, {
        method: "POST",
        headers: {
            jwt,
            "Content-Type": "application/json",
        },
        body: JSON.stringify({ slug }),
    });

    if (!res.ok) {
        throw new Error("Failed to make request");
    }

    return await res.json();
};

export const useDeleteBookmarkMutation = () => {
    const jwt = useJwt();

    return useMutation({
        mutationFn: ({ slug }: { slug: string }) => deleteBookmark(slug, jwt),

        onMutate: async (variables) => {
            await queryClient.cancelQueries({ queryKey: ["bookmarks"] });
            queryClient.setQueryData(["bookmarks"], (old: any) =>
                old.filter((bookmark: any) => bookmark !== variables.slug),
            );
            const previousBookmarks = queryClient.getQueryData(["bookmarks"]);

            const bookmarkedNewsArray = queryClient.getQueriesData({ queryKey: ["bookmarkedNews"] });
            // the idea is to fetch all cached data that have "bookmarkedNews" in them since we might have multiple of them
            // but we might not know the right cache combination to use
            // then we loop through them and remove the news from the array

            const allAffectedQueryKeys: any[] = [];
            // to store the keys of all the queries that will be affected by the delete
            // so we can use them onSuccess to refetch them

            bookmarkedNewsArray.forEach((res: any) => {
                const {
                    pages,
                }: {
                    pages: any[];
                    pageParams: any[];
                } = res[1];

                const allPages = pages.flatMap((page) => page.results) || [];

                const index = allPages.findIndex((news) => news.SK === variables.slug);
                if (index > -1) {
                    allAffectedQueryKeys.push(res[0]);
                    queryClient.setQueryData(res[0], (oldData: { pages; pageParams }) => {
                        if (!oldData) return;

                        // Modify the pages and pageParams as needed
                        const newPages = oldData.pages.map((page) => {
                            const findIndex = page.results.findIndex((news) => news.SK === variables.slug);
                            if (findIndex > -1) {
                                page.results.splice(findIndex, 1);
                            }
                            // Apply your changes to each page here
                            return {
                                ...page,
                                total: { ...page.total, value: page.total.value - 1 },
                            };
                        });

                        return {
                            ...oldData,
                            pages: newPages,
                            pageParams: oldData.pageParams,
                        };
                    });
                }
            });

            return { previousBookmarks, allAffectedQueryKeys };
        },

        onSuccess: (data, variables, context) => {
            queryClient.refetchQueries({ queryKey: ["bookmarks"] });

            context?.allAffectedQueryKeys.forEach((queryKey: any) => {
                queryClient.refetchQueries({ queryKey });
            });
        },

        onError: (error, variables, context) => {
            queryClient.setQueryData(["bookmarks"], context?.previousBookmarks);
        },
    });
};
