import { useState } from "react";
import SentimentGraph from "@assets/SVG/Sentiment/SentimentGraph";
import { Popover } from "react-tiny-popover";

const SentimentData = ({ sentimentData }) => {
    return (
        <div>
            <p className="mb-2 text-xs font-normal text-white">Sentiment</p>
            <SentimentGraph sentiment={sentimentData} />
            <div className="ml-8 mt-1 flex justify-between gap-1 text-[10px] font-extralight leading-[10px] text-[#959dab]">
                <div className="flex items-center gap-1">
                    <div className="size-1 bg-light-red" />
                    <p>Negative</p>
                </div>
                <div className="flex items-center gap-1">
                    <div className="size-1 bg-[#697486]" />
                    <p>Neutral</p>
                </div>
                <div className="flex items-center gap-1">
                    <div className="size-1 bg-secondary" />
                    <p>Positive</p>
                </div>
            </div>
        </div>
    );
};

export default function SentimentPopup({
    children,
    sentimentData,
}: {
    children: React.ReactNode;
    sentimentData;
}) {
    const [isPopoverOpen, setIsPopoverOpen] = useState(false);

    return (
        <Popover
            isOpen={isPopoverOpen}
            positions={["bottom", "top", "left", "right"]} // preferred positions by priority
            content={<SentimentData sentimentData={sentimentData} />}
            containerClassName="z-[1] pointer-events-none rounded-[10px] border border-oxford-blue bg-dark-blue p-2"
        >
            <div onPointerEnter={() => setIsPopoverOpen(true)} onPointerLeave={() => setIsPopoverOpen(false)}>
                {children}
            </div>
        </Popover>
    );
}
