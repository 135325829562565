import { Subject } from "rxjs";
import { BBApiClient, MarketCapInfo } from "./api/TSMarketApi";
import { getBBApiClient } from "./api/apihelper";
import { render } from "react-dom";

export async function fetchSymbolWatcherData(): Promise<any> {
    const apiCLient: BBApiClient = getBBApiClient();
    const dataFromAPi = await Promise.all([apiCLient.get()]);
    const rowData: MarketCapInfo[] = dataFromAPi[0].map((k) => {
        return mapSingleSymbolWatch(k);
    });
    return rowData;
}

export function mapSingleSymbolWatch(data: MarketCapInfo) {
    const z = data as ISymbolWatch;
    z.change = 0; //tempLastValGen(-15,15);
    z.changeStr = changeRenderer(z.change);
    z.last = 0; //tempLastValGen(5,1000);
    z.lastStr = lastRenderer(z.last, z.change);

    if (z.selfReportedMarketCap) {
        z.mcapStr = mcapRenderer(z.selfReportedMarketCap);
    }

    //z.asset = `<div><img class="swatch-symbol-icon" src="${data.icon}"/>${data.name} <span class="text-neutral">${data.symbol}</span></div>`;
    z.asset = `<img class="swatch-symbol-icon" src="${data.icon}"/><span class="text-white">${data.name}</span> <span class="text-neutral">${data.symbol}</span>`;
    z.symbol = data.symbol?.toLowerCase();
    return z;
}

export function changeRenderer(val: number) {
    let css = "";
    if (val < 0) {
        css = "text-light-red";
    }
    if (val > 0) {
        css = "text-secondary";
    }

    const renderVal = `${val == 0 ? "..." : val > 0 ? `+${val.toFixed(2)}%` : `${val.toFixed(2)}%`}`;

    return `<div class='w-100 text-right ${css}'>${renderVal}</div>`;
}

export function lastRenderer(val: number, change: number) {
    let css = "";
    if (change < 0) {
        css = "redchange";
    }
    if (change > 0) {
        css = "greenchange";
    }

    let renderVal = "";
    const changeAbsolute = Math.abs(val * change * 0.01);
    let digit = 2;
    let leftSubstr = "";
    let rightSubstr = "";

    const changeSplit = changeAbsolute.toString().split(".");
    const base = changeAbsolute.toString().split(".")[1];

    if (changeAbsolute < 1) {
        if (base) {
            const leads = base.match(/^0*/);
            const baselead = leads ? leads[0].length : 0;
            digit = baselead + 3;

            //     console.log("changeDigits",val,changeAbsolute,leads,digit);

            renderVal = `${val == 0 ? "" : `${TSgetLocaleStr(val, digit)}`}`;

            const dotLoc = renderVal.indexOf(".");

            if (dotLoc > -1) {
                var leftRange = dotLoc + baselead + 1;
                leftSubstr = renderVal.substring(0, leftRange);
                rightSubstr = renderVal.substr(leftRange, renderVal.length);
            }
        }
    } else {
        renderVal = `${val == 0 ? "" : `${TSgetLocaleStr(val, digit)}`}`;

        const dotLoc = renderVal.indexOf(".");
        const beforeDot = changeSplit[0].length;

        var leftRange = dotLoc - beforeDot;
        leftSubstr = renderVal.substr(0, leftRange);

        rightSubstr = renderVal.substr(leftRange, renderVal.length);
    }

    //  console.log("renderVal",renderVal,changeAbsolute,leftSubstr,rightSubstr);
    // if(val > 10e-2) {
    // renderVal = `${val == 0
    //     ? ""
    //     : `${TSgetLocaleStr(val,digit)}`
    //     }`;

    //    // changeDigits = val*change*0.01;
    // }

    // if(val <= 10e-2) {
    //     renderVal = `${val == 0
    //         ? ""
    //         : `${TSgetLocaleStr(val,6)}`
    //         }`;
    // }

    // if(val <= 10e-4) {
    //     renderVal = `${val == 0
    //         ? ""
    //         : `${TSgetLocaleStr(val,9)}`
    //         }`;
    // }

    return `<div class='w-100 text-left text-white'>$${leftSubstr}<span class='${css}'>${rightSubstr}</span></div>`;
}

export function TSgetLocaleStr(val: number, digits: number) {
    return val.toLocaleString("en-US", {
        minimumFractionDigits: digits,
        maximumFractionDigits: digits,
    });
}
export function mcapRenderer(val: number) {
    const billions = (val * 1) / Math.pow(10, 9);
    const millions = (val * 1) / Math.pow(10, 6);
    const thousands = (val * 1) / Math.pow(10, 3);

    let used = `${billions < 1 ? millions.toFixed(2) + "M" : billions.toFixed(2) + "B"}`;

    if (millions < 1) {
        used = `${thousands.toFixed(2)}K`;
    }

    return `<div class='w-100 text-left text-neutral'>${used}</div>`;
}

export function tempLastValGen(a, b) {
    const min = Math.min(a, b);
    const max = Math.max(a, b);
    const randomValue = Math.random() * (max - min) + min;
    const roundedValue = Math.round(randomValue * 100) / 100;
    return roundedValue;
}

export class ISymbolWatch extends MarketCapInfo {
    last?: number;
    lastStr?: string;
    change?: number;

    changeStr?: string;
    mcapStr?: string;
    asset?: string;
}

export class ISymbolName {
    id!: number;
    asset?: string;
    symbol?: string;
    selected?: boolean;
}

export class IWatchList {
    name?: string;
    symbols?: ISymbolName[];
}
export class TSDataExchangeClass {
    public mainSymbolSubject: Subject<string> = new Subject<string>();

    private static _instance: TSDataExchangeClass | null = null;
    public tempName?: string | null;

    public allRowsData: ISymbolWatch[] = [];
    public selectedTabRowsData: ISymbolWatch[] = [];
    public activeTopItemsNumber: number | null = null;
    public symbolsData: ISymbolName[] = [];
    public tempSymbolsData: ISymbolName[] = [];
    public allWatchLists: IWatchList[] = [];
    public activeTab?: string = TSDS.ALLASSETS;
    public connection?: signalR.HubConnection;
    public liveSorting: boolean = true;
    public activeMainSymbol: string = "btc";
    private constructor() {}
    public saveToLocalStorage() {
        localStorage.setItem("ts_watchlists", JSON.stringify(this.allWatchLists));
    }

    public loadLocalStorage() {
        const items = localStorage.getItem("ts_watchlists");
        if (items) {
            this.allWatchLists = JSON.parse(items);
        }
    }

    public static getInstance = (): TSDataExchangeClass => {
        if (!TSDataExchangeClass._instance) {
            TSDataExchangeClass._instance = new TSDataExchangeClass();
        }
        return TSDataExchangeClass._instance;
    };
}

export class ITabItem {
    id!: string;
    label!: string;
}
export enum TSDS {
    ALLASSETS = "All Assets",
}
//const TSDataExchangeClassInstance = TSDataExchangeClass.getInstance();
