import { useContext, useEffect, useRef, useState } from "react";
import {
    ChartOptions,
    ColorType,
    IChartApi,
    LineStyle,
    LineType,
    WhitespaceData,
    createChart,
} from "lightweight-charts";
import { PercentTag } from "../PercentTag";
import "./MiniChart.scss";
import { APIURL, fetchSymbolMiniChartData } from "@app/TSWidgets/api/apihelper";
import { QuoteData } from "@app/TSWidgets/api/TSMarketApi";
import * as signalR from "@microsoft/signalr";
import { lastRenderer } from "@app/TSWidgets/tswidgets";
import { ChartIntervals, ChartTimeRange } from "@/components/chart/ChartEnums";
import NewsItemContext from "@app/dashboard/news-feed/news-list/NewsItemContext";

export interface MiniChartProps {
    Symbol?: string;
}

export class QuoteService {
    static connection: signalR.HubConnection;

    constructor() {}

    public getConnection() {
        this.init();
        return QuoteService.connection;
    }

    private init(): void {
        if (!QuoteService.connection) {
            QuoteService.connection = new signalR.HubConnectionBuilder()
                .withUrl(`${APIURL}/hubs/main`, {
                    //.withUrl(`http://127.0.0.1:5003/hubs/main`, {
                    logMessageContent: true,
                    logger: signalR.LogLevel.Debug,
                })
                .configureLogging(signalR.LogLevel.Debug)
                .withAutomaticReconnect()
                .build();
            QuoteService.connection.keepAliveIntervalInMilliseconds = 5 * 1000;
            QuoteService.connection.serverTimeoutInMilliseconds = 32000; // 32s
            QuoteService.connection.start();
        }
    }
}

const quoteService = new QuoteService();
export function MiniChart({ Symbol = "BTC" }: MiniChartProps) {
    const news = useContext(NewsItemContext);
    const chart = useRef<IChartApi>();
    const chartContainerRef = useRef<HTMLDivElement>(null);
    const [symbolName, setSymbolName] = useState(Symbol);
    const [lastPrice, setLastPrice] = useState(0);
    const [mpchange, setMpChange] = useState(0);
    const [visible, setVisible] = useState(false);
    const handleResize = () => {
        if (!chart.current) {
            (chart.current as any)?.applyOptions({
                width:
                    (chartContainerRef?.current?.clientWidth ?? 0) > 6
                        ? (chartContainerRef?.current?.clientWidth ?? 0) - 6
                        : 0,
                height:
                    (chartContainerRef?.current?.clientHeight ?? 0) > 6
                        ? (chartContainerRef?.current?.clientHeight ?? 0) - 6
                        : 0,
            });
        }
    };

    async function waitUntilStateChangesWithTryCountAndAction(
        tryCount: number,
        stateFunction: () => boolean,
        action: () => Promise<any>,
    ): Promise<any> {
        let count = 0;
        return new Promise<any>((resolve, reject) => {
            const intervalId = setInterval(async () => {
                if (stateFunction()) {
                    clearInterval(intervalId);
                    try {
                        const result = await action();
                        resolve(result);
                    } catch (error) {
                        reject(error);
                    }
                } else {
                    count++;
                    if (count >= tryCount) {
                        clearInterval(intervalId);
                        // reject(new Error("State did not change within the specified try count."));
                        reject("State did not change within the specified try count.");
                    }
                }
            }, 100);
        });
    }

    useEffect(() => {
        if (!chart.current) {
            const chartOptions: any = {
                layout: {
                    textColor: "#7A869B",
                    fontSize: 10,
                    //fontFamily: "Inter",
                    background: {
                        type: ColorType.Solid,
                        color: "#2F343F", //white",
                    },
                },

                width:
                    (chartContainerRef?.current?.clientWidth ?? 0) > 6
                        ? (chartContainerRef?.current?.clientWidth ?? 0) - 6
                        : 0,
                height:
                    (chartContainerRef?.current?.clientHeight ?? 0) > 6
                        ? (chartContainerRef?.current?.clientHeight ?? 0) - 6
                        : 0,
                autoSize: true,
                watermark: {
                    text: "sss",
                    color: "white",
                    fontSize: 12,
                    visible: false,
                },
                leftPriceScale: {
                    scaleMargins: {
                        top: 0.4, // leave some space for the legend
                        bottom: 0.15,
                    },
                    ticksVisible: false,
                },
                rightPriceScale: undefined,
                overlayPriceScales: undefined,
                timeScale: {
                    secondsVisible: false,
                    timeVisible: true,
                    visible: true,
                    // tickMarkFormatter: (time) => {
                    //     const date = new Date(time * 1000);
                    //     return `${date.}` date.getFullYear() + '/' + (date.getMonth() + 1) + '/' + date.getDate();
                    // },
                },
                crosshair: {
                    // hide the horizontal crosshair line
                    horzLine: {
                        visible: true,
                        labelVisible: false,
                        color: "#D9CC5B80",
                        style: LineStyle.LargeDashed,
                        labelBackgroundColor: "#D9CC5B80",
                        width: 1,
                    },
                    vertLine: {
                        visible: true,
                        color: "#D9CC5B80",
                        style: LineStyle.LargeDashed,
                        labelBackgroundColor: "#D9CC5B80",
                        labelVisible: false,
                        width: 1,
                    },
                },
                grid: {
                    vertLines: {
                        visible: false,
                        color: "#3D4552",
                        style: LineStyle.Solid,
                    },
                    horzLines: {
                        visible: true,
                        color: "#3D4552",
                        style: LineStyle.Solid,
                    },
                },
                // localization: undefined,
                // handleScroll: false,
                // handleScale: false,
                // kineticScroll: undefined,
                // trackingMode: undefined,
            };
            chart.current = createChart(chartContainerRef.current!, chartOptions);

            const areaSeries = chart.current.addAreaSeries({
                topColor: "#D9CC5B80",
                bottomColor: "#D9CC5B10",
                lineColor: "#D9CC5B",
                lineWidth: 2,
                lastValueVisible: false,
                baseLineColor: "#D9CC5B",
                baseLineStyle: LineStyle.Solid,
                baseLineWidth: 1,
                baseLineVisible: true,
                lineStyle: LineStyle.Solid,
                lineType: LineType.Curved,
                //crossHairMarkerVisible: false,
            });

            fetchSymbolMiniChartData(Symbol, ChartTimeRange.ONE_DAY, ChartIntervals.ONE_MIN).then((d) => {
                setLastPrice(d?.q?.lp ?? 0);
                setMpChange(d?.q?.c ?? 0);
                setSymbolName(d?.name ?? "");
                const previous =
                    d.data.priceData.findLast((x) => x.date < new Date(news.publishedAt))?.close ?? 0;
                setMpChange(((d?.q?.lp || 0) - previous) / previous || 0);
                const data: any = d.data.priceData.slice(-15).map((v) => {
                    return { time: v.date.getTime() / 1000, value: v.close };
                });
                setVisible(true);
                areaSeries.setData(data as WhitespaceData[]);
                chart?.current?.timeScale().fitContent();

                quoteService.getConnection().on("Quote", (r: QuoteData[]) => {
                    const q = r.find((x) => x.s == Symbol.toLowerCase());
                    if (q) {
                        setLastPrice(q.lp);
                        setMpChange((q.lp - previous) / previous || 0);

                        const lastData: any = {
                            time: new Date().getTime() / 1000,
                            value: q.lp,
                        };
                        areaSeries.update(lastData as WhitespaceData);
                        // if (lastBarIndex >= 0) {
                        //     const lastBar = (data as WhitespaceData[])[lastBarIndex];
                        //     areaSeries.update({ time: lastBar.time, value: q.lp } as WhitespaceData);
                        // }
                    }
                });
                waitUntilStateChangesWithTryCountAndAction(
                    10,
                    () =>
                        quoteService.getConnection() &&
                        quoteService.getConnection().state == signalR.HubConnectionState.Connected,
                    async () => {
                        quoteService.getConnection().invoke("SubscribeSymbols", [Symbol.toLowerCase()]);
                    },
                );
                // setTimeout(() => {
                //     if (quoteService.getConnection()) {
                //         quoteService.getConnection().invoke("SubscribeSymbols", [Symbol.toLowerCase()]);
                //     }
                // }, 100);
            });

            chart.current.timeScale().fitContent();

            window.addEventListener("resize", handleResize);
        }
        return () => {
            window.removeEventListener("resize", handleResize);

            waitUntilStateChangesWithTryCountAndAction(
                10,
                () =>
                    quoteService.getConnection() &&
                    quoteService.getConnection().state == signalR.HubConnectionState.Connected,
                async () => {
                    quoteService.getConnection().invoke("SubscribeSymbols", []);
                },
            );

            // if (quoteService.getConnection()) {
            //     quoteService.getConnection().invoke("SubscribeSymbols", []);
            // }
        };
    }, []);

    return (
        <div className={`mini-chart`}>
            {!visible && (
                <div
                    style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        display: "inline-block",
                        zIndex: 100001,
                    }}
                >
                    <div className={`lds-roller`}>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
            )}
            <div
                className={`mini-chart-header`}
                style={{
                    visibility: visible ? "visible" : "hidden",
                }}
            >
                <div className={`mini-chart-symbol-name`}>{symbolName}</div>
                <div className={`mini-chart-stat flex gap-1`}>
                    <div className={`flex gap-0.5 font-normal text-white`}>
                        <div dangerouslySetInnerHTML={{ __html: lastRenderer(lastPrice, mpchange) }}></div>
                    </div>
                    <PercentTag percentChange={mpchange} />
                </div>
            </div>

            <div
                ref={chartContainerRef}
                className="mini-chart-container"
                style={{
                    visibility: visible ? "visible" : "hidden",
                }}
            />
        </div>
    );
}
