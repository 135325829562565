import { Dispatch, SetStateAction } from "react";
import MemoCopy from "@assets/SVG/Copy";
import MemoXBare from "@assets/SVG/XBare";
import MemoTelegramBare from "@assets/SVG/TelegramBare";
import MemoMail from "@assets/SVG/Mail";
import MemoFacebook from "@assets/SVG/Facebook";
import MemoRedditBare from "@assets/SVG/RedditBare";
import MemoLinkedInBare from "@assets/SVG/LinkedInBare";
import { News } from "@/types/api";
import { useCopyToClipboard } from "react-use";

import {
    EmailShareButton,
    FacebookShareButton,
    LinkedinShareButton,
    RedditShareButton,
    TelegramShareButton,
    TwitterShareButton,
} from "react-share";
import { useShortlinkBySlug } from "@/services/news";
import { Modal as ReactModal } from "react-responsive-modal";
import { XMarkIcon } from "@heroicons/react/24/outline";
import MemoLoading from "@assets/SVG/Loading";
import { useStytchUser } from "@stytch/nextjs";
import { trackEvent } from "@app/analytics/mixpanel";

export function ShareModal({
    news,
    show,
    setShow,
    setShowAlert,
    setAlertMessage,
}: {
    news: News;
    show: boolean;
    setShow: Dispatch<SetStateAction<boolean>>;
    setShowAlert: Dispatch<SetStateAction<boolean>>;
    setAlertMessage?: Dispatch<SetStateAction<string>>;
}) {
    const { data, isLoading } = useShortlinkBySlug(news.slug, show);
    const { user } = useStytchUser();

    const [value, copy] = useCopyToClipboard();

    let shareableLink = "";
    if (data?.shortlink) {
        shareableLink = `https://bb.buzz/s/${data?.shortlink}`;
    }

    const onCloseModal = () => setShow(false);

    const analyticsTracking = (medium: string) => {
        trackEvent(`News Share to ${medium}`, {
            slug: news.slug,
            shortlink: shareableLink,
            user_id: user?.user_id,
        });
    };

    return (
        <ReactModal
            open={show}
            onClose={onCloseModal}
            onOverlayClick={(e) => e.stopPropagation()} //because closing the modal was causing event propagation
            center
            focusTrapped={false}
            closeIcon={
                <XMarkIcon
                    onClick={(e) => {
                        //because closing the modal was causing event propagation
                        e.stopPropagation();
                        setShow(false);
                    }}
                    className="size-4 text-neutral"
                    strokeWidth={2.5}
                />
            }
            classNames={{
                modal: "modal-bg-none min-w-100 modal-radius-5 modal-padding-0 modal-margin-0",
            }}
        >
            <div className="w-100 flex flex-col items-start gap-3 rounded-lg border border-dark-blue bg-slate-blue px-0 pb-4 pt-0">
                <div className="flex w-full flex-col items-start gap-2 border-b border-dark-blue px-4 py-3">
                    <div className="flex items-center justify-between p-0">
                        <h2 className="share-modal text-base font-normal leading-5 text-white">
                            Share this article
                        </h2>
                    </div>
                    <p className="text-xs font-light text-neutral">Select one of the following options:</p>
                </div>
                <div className="flex flex-col items-start gap-3 px-4 py-0">
                    <div className="flex flex-col items-start gap-2 p-0">
                        <div className="flex items-start gap-2 p-0">
                            <TwitterShareButton
                                title="Check this out!"
                                hashtags={["CryptoNews", "BlockBeat"]}
                                url={shareableLink}
                                disabled={isLoading}
                                beforeOnClick={() => analyticsTracking("Twitter")}
                            >
                                <div className="flex w-[117.33px] cursor-pointer flex-col items-center justify-center gap-2.5 rounded-[50px] bg-dark-blue px-[9px] py-2.5 hover:brightness-90">
                                    <div className="flex size-5 items-center justify-center">
                                        <MemoXBare className="h-[15px] w-5" />
                                    </div>
                                </div>
                            </TwitterShareButton>
                            <TelegramShareButton
                                title="Check this out!"
                                url={shareableLink}
                                disabled={isLoading}
                                beforeOnClick={() => analyticsTracking("Telegram")}
                            >
                                <div className="flex w-[117.33px] cursor-pointer flex-col items-center justify-center gap-2.5 rounded-[50px] bg-dark-blue px-[9px] py-2.5 hover:brightness-90">
                                    <div className="flex size-5 items-center justify-center">
                                        <MemoTelegramBare className="h-[15px] w-[13px]" color="#FFFFFF" />
                                    </div>
                                </div>
                            </TelegramShareButton>
                            <EmailShareButton
                                subject={`${news.headline} - from BlockBeat`}
                                url={""}
                                body={`\n\n${shareableLink}\n\nHey, I thought you would find this interesting:\n\n${
                                    news?.content || news?.headline
                                }`}
                                separator=" "
                                disabled={isLoading}
                                beforeOnClick={() => analyticsTracking("Email")}
                            >
                                <div className="flex w-[117.33px] cursor-pointer flex-col items-center justify-center gap-2.5 rounded-[50px] bg-dark-blue px-[9px] py-2.5 hover:brightness-90">
                                    <div className="flex size-5 items-center justify-center">
                                        <MemoMail className="h-[15px] w-[13px]" color="#FFFFFF" />
                                    </div>
                                </div>
                            </EmailShareButton>
                        </div>
                        <div className="flex items-start gap-2 p-0">
                            <FacebookShareButton
                                quote={"Check this out!"}
                                url={shareableLink}
                                disabled={isLoading}
                                beforeOnClick={() => analyticsTracking("Facebook")}
                                // hashtag={"#yoiuuuu"}
                            >
                                {/* undone -  only showing hashtag */}
                                <div className="flex w-[117.33px] cursor-pointer flex-col items-center justify-center gap-2.5 rounded-[50px] bg-dark-blue px-[9px] py-2.5 hover:brightness-90">
                                    <div className="flex size-5 items-center justify-center">
                                        <MemoFacebook
                                            className="h-[15px] w-[13px]"
                                            color="#ffffff"
                                            fill="#2F343F"
                                        />
                                    </div>
                                </div>
                            </FacebookShareButton>
                            <RedditShareButton
                                title={`${news.headline} - from BlockBeat`}
                                url={shareableLink}
                                disabled={isLoading}
                                beforeOnClick={() => analyticsTracking("Reddit")}
                            >
                                <div className="flex w-[117.33px] cursor-pointer flex-col items-center justify-center gap-2.5 rounded-[50px] bg-dark-blue px-[9px] py-2.5 hover:brightness-90">
                                    <div className="flex size-5 items-center justify-center">
                                        <MemoRedditBare className="h-[15px] w-[13px]" color="#FFFFFF" />
                                    </div>
                                </div>
                            </RedditShareButton>
                            <LinkedinShareButton
                                source="BlockBeat"
                                title="Check this out!"
                                url={shareableLink}
                                disabled={isLoading}
                                beforeOnClick={() => analyticsTracking("LinkedIn")}
                            >
                                <div className="flex w-[117.33px] cursor-pointer flex-col items-center justify-center gap-2.5 rounded-[50px] bg-dark-blue px-[9px] py-2.5 hover:brightness-90">
                                    <div className="flex size-5 items-center justify-center">
                                        <MemoLinkedInBare className="h-[15px] w-[13px]" color="#FFFFFF" />
                                    </div>
                                </div>
                            </LinkedinShareButton>
                        </div>
                    </div>
                    <div className="flex w-full flex-col items-start gap-1 p-0">
                        <p className="text-xs font-light text-neutral">Or copy link:</p>
                        <div className="relative flex w-full items-center justify-between gap-2 rounded-lg border border-dark-blue p-3">
                            <div className="scrollbar-hide max-w-[19rem] overflow-y-hidden overflow-x-scroll scroll-smooth whitespace-nowrap">
                                <p className="cursor-text text-xs font-light text-white">{shareableLink}</p>
                            </div>
                            {/* <div className="horizontal-scroll-gradient absolute right-[2.45rem] h-4 w-3" /> */}

                            {isLoading ? (
                                <div className="my-auto flex justify-center">
                                    <MemoLoading className="size-4" />
                                </div>
                            ) : (
                                <div
                                    onClick={() => {
                                        if (setAlertMessage) {
                                            setAlertMessage("Article link copied!");
                                        }
                                        copy(shareableLink);
                                        trackEvent("Shareable Link Copy", {
                                            slug: news.slug,
                                            shortlink: shareableLink,
                                            user_id: user?.user_id,
                                        });
                                        setShowAlert(true);
                                        setShow(false);
                                    }}
                                    className="flex size-4 cursor-pointer items-center justify-center hover:brightness-75"
                                >
                                    <MemoCopy className="size-[11px]" />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </ReactModal>
    );
}
